<template>
  <div class="approval-builder">
    <div class="approval-builder__header">
      <h1>Согласования</h1>
      <div class="approval-builder__default">
        <esmp-button
          view="outline"
          size="small"
          icon="edit"
          @click="editApprovalDefaultForm('approve')"
        >
          Форма по умолчанию "Согласовать"
        </esmp-button>
        <esmp-button
          view="outline"
          size="small"
          icon="edit"
          @click="editApprovalDefaultForm('reject')"
        >
          Форма по умолчанию "Отклонить"
        </esmp-button>
      </div>
    </div>
    <esmp-table-wrapper
      class="approval-builder__list"
      title="Формы согласования"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <template #header-actions>
        <esmp-button
          size="small"
          icon="plus"
          @click="createApprovalForm"
        >
          Добавить
        </esmp-button>
      </template>
      <esmp-table
        :columns="columns"
        :rows="approvalForms"
        :loading="loading"
        stripe
      >
        <template #cell-approvalTypes="{ tr }">
          {{ getApprovalTypesName(tr.approvalTypes) }}
        </template>
        <template #cell-actionType="{ tr }">
          <esmp-tag
            shape="circular"
            :is-status="true"
            :color="getTagColor(tr.actionType)"
          >
            {{ getActionTypeName(tr.actionType) }}
          </esmp-tag>
        </template>
        <template #cell-edit="{ tr }">
          <esmp-button
            view="function"
            size="small"
            icon="doc-edit"
            @click="editApprovalForm(tr)"
          />
        </template>
        <template #cell-remove="{ tr }">
          <esmp-button
            view="function"
            size="small"
            icon="trash"
            @click="showConfirmModal(tr)"
          />
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <div v-if="showMore" class="approval-builder__show-more">
      <esmp-button
        @click="getMore"
      >
        Показать ещё
      </esmp-button>
    </div>
    <modal-confirm-action
      :showed="confirmModalShowed"
      header="Удаление формы"
      content="Вы уверены?"
      @confirm-action="removeApprovalForm"
      @cancel-action="cancelRemoveApprovalForm"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { APPROVE_STATUSES_MAP } from '@/constants/ticket';
import { ACTION_LIST, APPROVAL_PAGE_SIZE } from '@/constants/approval';
import ModalConfirmAction from '@/components/modals/modal-confirm-action';

export default {
  name: 'Approvals',
  components: {
    ModalConfirmAction,
  },
  data() {
    return {
      loading: false,
      showMore: true,
      currentPage: 0,
      columns: Object.freeze([
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Название',
          key: 'name',
        },
        {
          title: 'Услуга',
          key: 'serviceName',
        },
        {
          title: 'Тип согласования',
          key: 'approvalTypes',
        },
        {
          title: 'Действие',
          key: 'actionType',
        },
        {
          title: '',
          key: 'edit',
          width: '42px',
        },
        {
          title: '',
          key: 'remove',
          width: '42px',
        },
      ]),
      approvalForms: [],
      formToDelete: null,
      confirmModalShowed: false,
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getTagColor(actionType) {
      return actionType === 'approve' ? APPROVE_STATUSES_MAP.Approved.color : APPROVE_STATUSES_MAP.Denied.color;
    },
    getActionTypeName(actionType) {
      return ACTION_LIST.find((a) => a.value === actionType)?.name || 'Неизвестно';
    },
    createApprovalForm() {
      this.$router.push({ name: 'CreateApproval' });
    },
    getApprovalTypesName(approvalTypes) {
      return (approvalTypes || []).join(', ');
    },
    editApprovalForm(item) {
      this.$router.push({ name: 'EditApproval', params: { id: item.id } });
    },
    editApprovalDefaultForm(action) {
      this.$router.push({ name: 'EditDefaultApproval', query: { isDefault: true, action } });
    },
    showConfirmModal(item) {
      this.formToDelete = item;
      this.confirmModalShowed = true;
    },
    async removeApprovalForm() {
      this.setLoading({ key: 'page', value: true });
      this.$API.approval.deleteApprovalForm(this.formToDelete.id)
        .then(() => {
          this.approvalForms = this.approvalForms.filter((form) => form.id !== this.formToDelete.id);
          this.confirmModalShowed = false;
          this.formToDelete = null;
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    cancelRemoveApprovalForm() {
      this.confirmModalShowed = false;
      this.formToDelete = null;
    },
    getApprovalForms() {
      this.setLoading({ key: 'page', value: true });
      this.$API.approval.getApprovalForms(this.currentPage, APPROVAL_PAGE_SIZE)
        .then(({ data }) => {
          this.approvalForms = this.approvalForms.concat(data || []);
          if (data?.length < APPROVAL_PAGE_SIZE) {
            this.showMore = false;
          }
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    getMore() {
      this.currentPage += 1;
      this.getApprovalForms();
    },
  },
  created() {
    this.getApprovalForms();
  },
};
</script>

<style lang="scss">
.approval-builder {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $base-gutter;
  }
  &__default {
    display: flex;
    gap: $base-gutter;
  }
  &__show-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
